<template>
  <div v-if="dataObject.attributeOption.length">
    <b-row class="mt-3">
      <b-col>
        <b-table-simple small caption-top responsive class="combination-table">
          <b-thead head-variant="light">
            <b-tr>
              <slot v-for="attr in attributes">
                <b-th>{{ attr.label }}</b-th>
              </slot>
              <b-th>ราคาเต็ม <span class="text-danger">*</span></b-th>
              <b-th>ราคาขาย <span class="text-danger">*</span></b-th>

              <b-th>สต๊อก<span class="text-danger">*</span></b-th>
              <!-- <b-th>GP <span class="text-danger">*</span></b-th> -->
              <b-th>SKU <span class="text-danger">*</span></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <slot v-for="(el1, index1) in dataObject.attributeOption">
              <template v-if="!el1.attribute">
                <b-tr>
                  <b-td class="custom-column-text">
                    {{ el1.label }}
                  </b-td>
                  <b-td class="custom-column-number">
                    <b-form-input
                      class="input-children"
                      @keypress="isNumber($event)"
                      v-model="el1.product.straightPrice"
                      :class="{
                        'combitable-error':
                          v.$each.$iter[index1].product.straightPrice.$error,
                      }"
                    ></b-form-input>
                  </b-td>
                  <b-td class="custom-column-number">
                    <b-form-input
                      class="input-children"
                      @keypress="isNumber($event)"
                      @keyup="handleZero($event)"
                      v-model="el1.product.rawPrice"
                      :class="{
                        'combitable-error':
                          v.$each.$iter[index1].product.rawPrice.$error,
                      }"
                    ></b-form-input>
                  </b-td>
                  <b-td class="custom-column-number">
                    <b-form-input
                      class="input-children"
                      @keypress="isNumber($event)"
                      v-model="el1.product.quantity"
                      :class="{
                        'combitable-error':
                          v.$each.$iter[index1].product.quantity.$error,
                      }"
                    ></b-form-input>
                  </b-td>
                  <!-- <b-td class="custom-column-number"> -->
                  <!-- <b-form-input
                      class="input-children"
                      @keypress="isNumber($event)"
                      @keyup="handleZero($event)"
                      v-model="el1.product.gp"
                      :disabled="true"
                    ></b-form-input> -->
                  <!-- :class="{
                        'combitable-error':
                          v.$each.$iter[index1].product.gp.$error,
                      }" -->
                  <!-- </b-td> -->
                  <b-td>
                    <b-form-input
                      class="input-children"
                      v-model="el1.product.sku"
                      :class="{
                        'combitable-error':
                          v.$each.$iter[index1].product.sku.$error,
                      }"
                    ></b-form-input>
                  </b-td>
                </b-tr>
              </template>
              <template v-else>
                <slot v-for="(el2, index2) in el1.attribute.attributeOption">
                  <template v-if="!el2.attribute">
                    <b-tr>
                      <b-td
                        class="custom-column-text"
                        :rowspan="el1.attribute.attributeOption.length"
                        v-if="index2 == 0"
                      >
                        {{ el1.label }}
                      </b-td>
                      <b-td class="custom-column-text">
                        {{ el2.label }}
                      </b-td>

                      <b-td class="custom-column-number">
                        <b-form-input
                          class="input-children"
                          @keypress="isNumber($event)"
                          v-model="el2.product.straightPrice"
                          :class="{
                            'combitable-error':
                              v.$each.$iter[index1].attribute.attributeOption
                                .$each.$iter[index2].product.straightPrice
                                .$error,
                          }"
                        ></b-form-input>
                      </b-td>
                      <b-td class="custom-column-number">
                        <b-form-input
                          class="input-children"
                          @keypress="isNumber($event)"
                          @keyup="handleZero($event)"
                          v-model="el2.product.rawPrice"
                          :class="{
                            'combitable-error':
                              v.$each.$iter[index1].attribute.attributeOption
                                .$each.$iter[index2].product.rawPrice.$error,
                          }"
                        ></b-form-input>
                      </b-td>
                      <b-td class="custom-column-number">
                        <b-form-input
                          class="input-children"
                          @keypress="isNumber($event)"
                          v-model="el2.product.quantity"
                          :class="{
                            'combitable-error':
                              v.$each.$iter[index1].attribute.attributeOption
                                .$each.$iter[index2].product.quantity.$error,
                          }"
                        ></b-form-input>
                      </b-td>
                      <!-- <b-td class="custom-column-number">
                        <b-form-input
                          class="input-children"
                          @keypress="isNumber($event)"
                          @keyup="handleZero($event)"
                          v-model="el2.product.gp"
                          :disabled="true"
                        ></b-form-input> -->
                      <!-- :class="{ 'combitable-error':
                        v.$each.$iter[index1].attribute.attributeOption
                        .$each.$iter[index2].product.gp.$error, }" -->
                      <!-- </b-td> -->
                      <b-td>
                        <b-form-input
                          class="input-children"
                          v-model="el2.product.sku"
                          :class="{
                            'combitable-error':
                              v.$each.$iter[index1].attribute.attributeOption
                                .$each.$iter[index2].product.sku.$error,
                          }"
                        ></b-form-input>
                      </b-td>
                    </b-tr>
                  </template>
                  <template v-else>
                    <slot
                      v-for="(el3, index3) in el2.attribute.attributeOption"
                    >
                      <b-tr>
                        <b-td
                          class="custom-column-text"
                          :rowspan="
                            el2.attribute.attributeOption.length *
                            el1.attribute.attributeOption.length
                          "
                          v-if="index2 === 0 && index3 === 0"
                        >
                          {{ el1.label }}
                        </b-td>
                        <b-td
                          class="custom-column-text"
                          :rowspan="el2.attribute.attributeOption.length"
                          v-if="index3 === 0"
                        >
                          {{ el2.label }}
                        </b-td>
                        <b-td class="custom-column-text">
                          {{ el3.label }}
                        </b-td>

                        <b-td class="custom-column-number">
                          <b-form-input
                            class="input-children"
                            @keypress="isNumber($event)"
                            v-model="el3.product.straightPrice"
                            :class="{
                              'combitable-error':
                                v.$each.$iter[index1].attribute.attributeOption
                                  .$each.$iter[index2].attribute.attributeOption
                                  .$each.$iter[index3].product.straightPrice
                                  .$error,
                            }"
                          ></b-form-input>
                        </b-td>
                        <b-td class="custom-column-number">
                          <b-form-input
                            class="input-children"
                            @keypress="isNumber($event)"
                            @keyup="handleZero($event)"
                            v-model="el3.product.rawPrice"
                            :class="{
                              'combitable-error':
                                v.$each.$iter[index1].attribute.attributeOption
                                  .$each.$iter[index2].attribute.attributeOption
                                  .$each.$iter[index3].product.rawPrice.$error,
                            }"
                          ></b-form-input>
                        </b-td>
                        <b-td class="custom-column-number">
                          <b-form-input
                            class="input-children"
                            @keypress="isNumber($event)"
                            v-model="el3.product.quantity"
                            :class="{
                              'combitable-error':
                                v.$each.$iter[index1].attribute.attributeOption
                                  .$each.$iter[index2].attribute.attributeOption
                                  .$each.$iter[index3].product.quantity.$error,
                            }"
                          ></b-form-input>
                        </b-td>
                        <!-- <b-td class="custom-column-number">
                          <b-form-input
                            :disabled="true"
                            class="input-children"
                            @keypress="isNumber($event)"
                            @keyup="handleZero($event)"
                            v-model="el3.product.gp"
                          ></b-form-input> -->
                        <!-- :class="{ 'combitable-error':
                          v.$each.$iter[index1].attribute.attributeOption
                          .$each.$iter[index2].attribute.attributeOption
                          .$each.$iter[index3].product.gp.$error, }" -->
                        <!-- </b-td> -->
                        <b-td>
                          <b-form-input
                            class="input-children"
                            v-model="el3.product.sku"
                            :class="{
                              'combitable-error':
                                v.$each.$iter[index1].attribute.attributeOption
                                  .$each.$iter[index2].attribute.attributeOption
                                  .$each.$iter[index3].product.sku.$error,
                            }"
                          ></b-form-input>
                        </b-td>
                      </b-tr>
                    </slot>
                  </template>
                </slot>
              </template>
            </slot>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <!-- <span class="text-error" v-if="v.$error == true">
          {{ $t("note") }}: {{ $t("minProductGP") }} {{ productGP }}%
          {{ $t("and") }} {{ $t("productPrice") }}
          {{ $t("productPriceError") }} 0
        </span> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "CombinationTable",
  props: {
    dataObject: {
      required: false,
      type: Object,
    },
    attributes: {
      required: false,
      type: Array,
    },
    v: {
      required: false,
      type: Object,
    },
    productGP: {
      required: false,
      type: Number,
    },
  },
  created() {},
  methods: {
    handleZero: function (evt) {
      let value = evt.target.value;
      value = value.replace(/^0+/, "");
      evt.target.value = value;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-column-number {
  max-width: 100px;
}

.custom-column-text {
  max-width: 150px;
}
.custom-column {
  max-width: 220px;
}
.input-children {
  width: 100%;
}
.text-error {
  color: #ff0000;
  font-size: 14px;
}
.combitable-error {
  border-color: red;
}
.combination-table.table thead th,
.table td {
  border: 1px solid #ebebeb;
}

@media (max-width: 600px) {
  .input-children {
    width: 100px;
  }
}
</style>
