var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.dataObject.attributeOption.length)?_c('div',[_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('b-table-simple',{staticClass:"combination-table",attrs:{"small":"","caption-top":"","responsive":""}},[_c('b-thead',{attrs:{"head-variant":"light"}},[_c('b-tr',[_vm._l((_vm.attributes),function(attr){return _vm._t("default",function(){return [_c('b-th',[_vm._v(_vm._s(attr.label))])]})}),_c('b-th',[_vm._v("ราคาเต็ม "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-th',[_vm._v("ราคาขาย "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-th',[_vm._v("สต๊อก"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-th',[_vm._v("SKU "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])],2)],1),_c('b-tbody',[_vm._l((_vm.dataObject.attributeOption),function(el1,index1){return _vm._t("default",function(){return [(!el1.attribute)?[_c('b-tr',[_c('b-td',{staticClass:"custom-column-text"},[_vm._v(" "+_vm._s(el1.label)+" ")]),_c('b-td',{staticClass:"custom-column-number"},[_c('b-form-input',{staticClass:"input-children",class:{
                      'combitable-error':
                        _vm.v.$each.$iter[index1].product.straightPrice.$error,
                    },on:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(el1.product.straightPrice),callback:function ($$v) {_vm.$set(el1.product, "straightPrice", $$v)},expression:"el1.product.straightPrice"}})],1),_c('b-td',{staticClass:"custom-column-number"},[_c('b-form-input',{staticClass:"input-children",class:{
                      'combitable-error':
                        _vm.v.$each.$iter[index1].product.rawPrice.$error,
                    },on:{"keypress":function($event){return _vm.isNumber($event)},"keyup":function($event){return _vm.handleZero($event)}},model:{value:(el1.product.rawPrice),callback:function ($$v) {_vm.$set(el1.product, "rawPrice", $$v)},expression:"el1.product.rawPrice"}})],1),_c('b-td',{staticClass:"custom-column-number"},[_c('b-form-input',{staticClass:"input-children",class:{
                      'combitable-error':
                        _vm.v.$each.$iter[index1].product.quantity.$error,
                    },on:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(el1.product.quantity),callback:function ($$v) {_vm.$set(el1.product, "quantity", $$v)},expression:"el1.product.quantity"}})],1),_c('b-td',[_c('b-form-input',{staticClass:"input-children",class:{
                      'combitable-error':
                        _vm.v.$each.$iter[index1].product.sku.$error,
                    },model:{value:(el1.product.sku),callback:function ($$v) {_vm.$set(el1.product, "sku", $$v)},expression:"el1.product.sku"}})],1)],1)]:[_vm._l((el1.attribute.attributeOption),function(el2,index2){return _vm._t("default",function(){return [(!el2.attribute)?[_c('b-tr',[(index2 == 0)?_c('b-td',{staticClass:"custom-column-text",attrs:{"rowspan":el1.attribute.attributeOption.length}},[_vm._v(" "+_vm._s(el1.label)+" ")]):_vm._e(),_c('b-td',{staticClass:"custom-column-text"},[_vm._v(" "+_vm._s(el2.label)+" ")]),_c('b-td',{staticClass:"custom-column-number"},[_c('b-form-input',{staticClass:"input-children",class:{
                          'combitable-error':
                            _vm.v.$each.$iter[index1].attribute.attributeOption
                              .$each.$iter[index2].product.straightPrice
                              .$error,
                        },on:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(el2.product.straightPrice),callback:function ($$v) {_vm.$set(el2.product, "straightPrice", $$v)},expression:"el2.product.straightPrice"}})],1),_c('b-td',{staticClass:"custom-column-number"},[_c('b-form-input',{staticClass:"input-children",class:{
                          'combitable-error':
                            _vm.v.$each.$iter[index1].attribute.attributeOption
                              .$each.$iter[index2].product.rawPrice.$error,
                        },on:{"keypress":function($event){return _vm.isNumber($event)},"keyup":function($event){return _vm.handleZero($event)}},model:{value:(el2.product.rawPrice),callback:function ($$v) {_vm.$set(el2.product, "rawPrice", $$v)},expression:"el2.product.rawPrice"}})],1),_c('b-td',{staticClass:"custom-column-number"},[_c('b-form-input',{staticClass:"input-children",class:{
                          'combitable-error':
                            _vm.v.$each.$iter[index1].attribute.attributeOption
                              .$each.$iter[index2].product.quantity.$error,
                        },on:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(el2.product.quantity),callback:function ($$v) {_vm.$set(el2.product, "quantity", $$v)},expression:"el2.product.quantity"}})],1),_c('b-td',[_c('b-form-input',{staticClass:"input-children",class:{
                          'combitable-error':
                            _vm.v.$each.$iter[index1].attribute.attributeOption
                              .$each.$iter[index2].product.sku.$error,
                        },model:{value:(el2.product.sku),callback:function ($$v) {_vm.$set(el2.product, "sku", $$v)},expression:"el2.product.sku"}})],1)],1)]:[_vm._l((el2.attribute.attributeOption),function(el3,index3){return _vm._t("default",function(){return [_c('b-tr',[(index2 === 0 && index3 === 0)?_c('b-td',{staticClass:"custom-column-text",attrs:{"rowspan":el2.attribute.attributeOption.length *
                          el1.attribute.attributeOption.length}},[_vm._v(" "+_vm._s(el1.label)+" ")]):_vm._e(),(index3 === 0)?_c('b-td',{staticClass:"custom-column-text",attrs:{"rowspan":el2.attribute.attributeOption.length}},[_vm._v(" "+_vm._s(el2.label)+" ")]):_vm._e(),_c('b-td',{staticClass:"custom-column-text"},[_vm._v(" "+_vm._s(el3.label)+" ")]),_c('b-td',{staticClass:"custom-column-number"},[_c('b-form-input',{staticClass:"input-children",class:{
                            'combitable-error':
                              _vm.v.$each.$iter[index1].attribute.attributeOption
                                .$each.$iter[index2].attribute.attributeOption
                                .$each.$iter[index3].product.straightPrice
                                .$error,
                          },on:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(el3.product.straightPrice),callback:function ($$v) {_vm.$set(el3.product, "straightPrice", $$v)},expression:"el3.product.straightPrice"}})],1),_c('b-td',{staticClass:"custom-column-number"},[_c('b-form-input',{staticClass:"input-children",class:{
                            'combitable-error':
                              _vm.v.$each.$iter[index1].attribute.attributeOption
                                .$each.$iter[index2].attribute.attributeOption
                                .$each.$iter[index3].product.rawPrice.$error,
                          },on:{"keypress":function($event){return _vm.isNumber($event)},"keyup":function($event){return _vm.handleZero($event)}},model:{value:(el3.product.rawPrice),callback:function ($$v) {_vm.$set(el3.product, "rawPrice", $$v)},expression:"el3.product.rawPrice"}})],1),_c('b-td',{staticClass:"custom-column-number"},[_c('b-form-input',{staticClass:"input-children",class:{
                            'combitable-error':
                              _vm.v.$each.$iter[index1].attribute.attributeOption
                                .$each.$iter[index2].attribute.attributeOption
                                .$each.$iter[index3].product.quantity.$error,
                          },on:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(el3.product.quantity),callback:function ($$v) {_vm.$set(el3.product, "quantity", $$v)},expression:"el3.product.quantity"}})],1),_c('b-td',[_c('b-form-input',{staticClass:"input-children",class:{
                            'combitable-error':
                              _vm.v.$each.$iter[index1].attribute.attributeOption
                                .$each.$iter[index2].attribute.attributeOption
                                .$each.$iter[index3].product.sku.$error,
                          },model:{value:(el3.product.sku),callback:function ($$v) {_vm.$set(el3.product, "sku", $$v)},expression:"el3.product.sku"}})],1)],1)]})})]]})})]]})})],2)],1)],1)],1),_c('b-row',[_c('b-col')],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }